/* components/FxNewReading.js | New glucose reading input component with validation | Sree | 24 Nov 2024 */

'use client';

import { useState } from 'react';
import { Grid, Stack, TextInput, Checkbox, Button, Group, NumberInput, Textarea } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export default function FxNewReading({ onSubmit, onError }) {
    const isMobile = useMediaQuery('(max-width: 768px)');
    /* Function to get current local datetime in ISO format */
    const getLocalDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const [reading, setReading] = useState(() => ({
        datetime: getLocalDateTime(),
        value: '',
        isFasting: true,
        notes: ''
    }));
    const [submitting, setSubmitting] = useState(false);

    const handleChange = (field, value) => setReading(prev => ({ ...prev, [field]: value }));

    const formatDateForGS = (datetime) => {
        const date = new Date(datetime);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            timeZone: 'Asia/Kolkata'
        });
    };

    const formatTimeForGS = (datetime) => {
        const date = new Date(datetime);
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Asia/Kolkata'
        });
    };

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            if (!reading.datetime || !reading.value) throw new Error('Please fill in all required fields');

            const formattedData = [
                formatDateForGS(reading.datetime),
                formatTimeForGS(reading.datetime),
                reading.isFasting ? reading.value : '',
                reading.isFasting ? '' : reading.value,
                reading.notes.trim() || ''
            ];

            const response = await fetch('/api/gluco', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ values: formattedData })
            });

            if (!response.ok) throw new Error('Failed to save reading');
            const result = await response.json();

            /* Update local cache */
            const newRow = { id: `row-${result.rowIndex}-${Date.now()}`, values: formattedData };
            const cached = localStorage.getItem('FxLifeLog');
            if (cached) {
                const { GSData, timestamp } = JSON.parse(cached);
                GSData.unshift(newRow);
                localStorage.setItem('FxLifeLog', JSON.stringify({ GSData, timestamp: Date.now() }));
            }

            /* Reset form with current date/time */
            setReading({
                datetime: getLocalDateTime(),
                value: '',
                isFasting: true,
                notes: ''
            });
            onSubmit?.(newRow);
        } catch (error) {
            console.error('Error saving reading:', error);
            onError?.(error.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Stack gap="md">
            <Grid grow justify="space-between" align="center">
                <Grid.Col span={9}>
                <TextInput
                    type="datetime-local" value={reading.datetime}
                    onChange={(e) => handleChange('datetime', e.target.value)} required
                    classNames={{ input: 'cursor-pointer' }}
                    styles={{ input: { fontSize: isMobile ? '16px' : '14px', }, }}
                />
                </Grid.Col>
                <Grid.Col span={3}>
                <Checkbox
                    label="Fasting" checked={reading.isFasting}
                    onChange={(e) => handleChange('isFasting', e.currentTarget.checked)}
                />
                </Grid.Col>
            </Grid>
            <NumberInput
                onChange={(value) => handleChange('value', value)} min={0} max={500} required placeholder="Enter glucose reading" data-autofocus inputMode="numeric" pattern="[0-9]*"
                styles={{ input: { fontSize: isMobile ? '16px' : '14px', }, }}
            />
            <Textarea
                // label="Notes" value={reading.notes}
                onChange={(e) => handleChange('notes', e.target.value)}
                placeholder="Add any notes (optional)" minRows={2} maxRows={4}
                styles={{ input: { fontSize: isMobile ? '16px' : '14px', }, }}
            />
            <Group justify="flex-end">
                <Button onClick={handleSubmit} loading={submitting} disabled={!reading.value || !reading.datetime} >
                    Save Reading
                </Button>
            </Group>
        </Stack>
    );
}

/* - - - - - - - - - - - - - - - - */