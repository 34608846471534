/**
 * components/FxLoadingSkeleton.js
 * Reusable loading skeleton component for consistent loading states
 */

'use client';

import { Skeleton, Stack, Grid } from '@mantine/core';

const FxLoadingSkeleton = ({ type = 'table' }) => {
  if (type === 'table') {
    return (
      <Stack gap="md">
        <Grid>
          {[1, 2, 3, 4].map((i) => (
            <Grid.Col key={i} span={{ base: 12, sm: 6, md: 3 }}>
              <Skeleton height={90} radius="md" />
            </Grid.Col>
          ))}
        </Grid>
        {[1, 2, 3, 4, 5].map((i) => (
          <Skeleton key={i} height={40} radius="sm" />
        ))}
      </Stack>
    );
  }

  if (type === 'stats') {
    return (
      <Grid>
        {[1, 2, 3, 4].map((i) => (
          <Grid.Col key={i} span={{ base: 12, sm: 6, md: 3 }}>
            <Skeleton height={90} radius="md" />
          </Grid.Col>
        ))}
      </Grid>
    );
  }

  return <Skeleton height={400} radius="md" />;
};

export default FxLoadingSkeleton;

/* - - - - - - - - - - - - - - - - */