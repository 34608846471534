/* src/app/components/FxLayout.js | Responsive layout with top navigation | Sree | 24 Nov 2024 */
'use client';

import { useState } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { AppShell, Drawer, Group, Button, Title, Container, ActionIcon, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconChartBar, IconCalendar, IconList, IconRefresh, IconPlus, IconX } from '@tabler/icons-react';
import FxNewReading from './FxNewReading';
import { fetchAndCacheData } from './FxUtils';

const navigation = [
    { label: 'Trends', path: '/', icon: IconChartBar },
    { label: 'Calendar', path: '/cal', icon: IconCalendar },
    { label: 'Logs', path: '/logs', icon: IconList },
];

const FxLayout = ({ children, onDataUpdate }) => {
    const router = useRouter();
    const pathname = usePathname();
    const [opened, setOpened] = useState(false);
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

    const handleNewReading = () => {
        notifications.show({
            title: 'Success',
            message: 'New reading saved successfully',
            color: 'green'
        });
        if (['/logs', '/trends', '/'].includes(pathname)) onDataUpdate?.();
        setOpened(false);
    };

    const handleError = (message) => notifications.show({ title: 'Error', message, color: 'red' });

    const handleRefresh = async () => {
        try {
            const result = await fetchAndCacheData(true);
            if (result) { window.location.reload(); }
        } catch (error) {
            notifications.show({ color: 'red', title: 'Error', message: 'Failed to refresh data', })
        }
    };

    return (
        <AppShell
            header={{ height: 64 }}
            padding="md"
            styles={{
                main: {
                    paddingBottom: '80px'  // Space for floating button
                }
            }}
        >
            <AppShell.Header>
                <Container size="xl" style={{ height: '100%' }}>
                    <Group h="100%" justify="space-between">
                        {/* Logo */}
                        <Title order={3} c="blue" size={{ base: 'h4', sm: 'h3' }}>LifeLogs</Title>

                        <Group gap="md">
                            {/* Desktop Navigation - Text Buttons */}
                            <Group gap="xl" hiddenFrom="sm">
                                {navigation.map(({ label, path, icon: Icon }) => (
                                    <ActionIcon
                                        key={path}
                                        variant={pathname === path ? 'filled' : 'subtle'}
                                        color="blue"
                                        size="lg"
                                        onClick={() => router.push(path)}
                                        title={label}
                                    >
                                        <Icon size={20} />
                                    </ActionIcon>
                                ))}
                            </Group>

                            {/* Mobile Navigation - Icons */}
                            <Group gap="xl" visibleFrom="sm">
                                {navigation.map(({ label, path }) => (
                                    <Button
                                        key={path}
                                        variant={pathname === path ? 'filled' : 'subtle'}
                                        onClick={() => router.push(path)}
                                    >
                                        {label}
                                    </Button>
                                ))}
                            </Group>

                            {/* Refresh Button */}
                            <ActionIcon
                                variant="subtle"
                                size="lg"
                                onClick={handleRefresh}
                                title="Refresh data"
                            >
                                <IconRefresh size={20} />
                            </ActionIcon>
                        </Group>
                    </Group>
                </Container>
            </AppShell.Header>

            <AppShell.Main>
                <Container size="lg" py="md" px={{ base: 'xs', sm: 'md' }}>
                    {children}
                </Container>

                {/* Floating Add Button - Universal */}
                <ActionIcon
                    variant="filled"
                    color="blue"
                    size="xl"
                    radius="xl"
                    onClick={() => setOpened(true)}
                    style={{
                        position: 'fixed',
                        bottom: '2rem',
                        right: '2rem',
                        zIndex: 999
                    }}
                >
                    <IconPlus size={24} />
                </ActionIcon>

                {/* Universal Bottom Drawer */}
                <Drawer
                    position="bottom"
                    opened={opened}
                    onClose={() => setOpened(false)}
                    size={isMobile ? 'auto' : '100%'}
                    zIndex={2244}
                    title="New Log"
                    styles={{
                        inner: {
                            top: 'auto',
                            bottom: 0,
                            height: 'auto',
                            maxHeight: '90vh',
                        },
                        header: { padding: '8px 16px', background: '#F9FAFA', },
                        title: { color: 'DimGray', fontWeight: '500', },
                        content: {
                            maxWidth: '600px',
                            margin: '0 auto',
                            padding: '0',
                            borderRadius: '8px 8px 0 0',
                        },
                        body: { padding: '24px', },
                    }}
                    closeButtonProps={{ icon: <IconX size={24} /> }}
                >
                    <FxNewReading onSubmit={handleNewReading} onError={handleError} />
                </Drawer>
            </AppShell.Main>
        </AppShell>
    );
};

export default FxLayout;
/* - - - - - - - - - - - - - - - - */