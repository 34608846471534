/* components/FxUtils.js | Utility functions for data fetching, formatting, and calculations | Sree | 24 Nov 2024 */

'use client';

import { useState, useEffect } from 'react';

const CACHE_KEY = 'FxLifeLog';
const CACHE_DURATION = 1000 * 60 * 5; // 5 minutes

/**
 * Date and Time Formatting Utilities
 */
export const formatUtils = {
  dateToInput: (dateStr) => {
    if (!dateStr?.includes('/')) return dateStr || '';
    const [month, day, year] = dateStr.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  },

  dateToDisplay: (dateStr) => {
    if (!dateStr?.includes('-')) return dateStr || '';
    const [year, month, day] = dateStr.split('-');
    return `${month}/${day}/${year}`;
  },

  timeToInput: (timeStr) => {
    if (!timeStr) return '';
    const match = timeStr.match(/(\d{1,2}):(\d{2})\s*(AM|PM)/i);
    if (!match) return timeStr;
    
    let [_, hours, minutes, period] = match;
    hours = parseInt(hours);
    if (period.toUpperCase() === 'PM' && hours < 12) hours += 12;
    if (period.toUpperCase() === 'AM' && hours === 12) hours = 0;
    
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  },

  timeToDisplay: (timeStr) => {
    if (!timeStr) return '';
    const [hoursStr, minutes] = timeStr.split(':');
    const hours = parseInt(hoursStr);
    const period = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours % 12 || 12;
    return `${displayHours}:${minutes}${period}`;
  }
};

/**
 * Synchronously retrieves cached data
 */
const getCachedData = () => {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return null;
    
    const { GSData, timestamp } = JSON.parse(cached);
    return Date.now() - timestamp < CACHE_DURATION ? GSData : null;
  } catch (error) {
    console.error('Cache read error:', error);
    return null;
  }
};

/**
 * Fetches fresh data from the API
 */
const fetchFreshData = async () => {
  try {
    const response = await fetch('/api/gluco');
    const result = await response.json();
    
    const processedData = result.data.map((row, index) => ({
      id: `row-${index}-${Date.now()}`,
      values: row
    }));

    localStorage.setItem(CACHE_KEY, JSON.stringify({
      GSData: processedData,
      timestamp: Date.now()
    }));

    return processedData;
  } catch (error) {
    throw new Error('Failed to fetch fresh data');
  }
};

/**
 * Enhanced data fetching with optimized caching
 */
export const fetchAndCacheData = async (forceRefresh = false) => {
  try {
    // Try cache first if not forcing refresh
    if (!forceRefresh) {
      const cachedData = getCachedData();
      if (cachedData) {
        // If cache is getting stale, refresh in background
        const { timestamp } = JSON.parse(localStorage.getItem(CACHE_KEY));
        if (Date.now() - timestamp > CACHE_DURATION / 2) {
          fetchFreshData().catch(console.error); // Silent background refresh
        }
        return cachedData;
      }
    }

    return await fetchFreshData();
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

/**
 * Custom hook for managing data loading state
 */
export const useDataLoader = (options = {}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadData = async (forceRefresh = false) => {
    try {
      setLoading(true);
      const result = await fetchAndCacheData(forceRefresh);
      setData(Array.isArray(result) ? result : []);
    } catch (err) {
      setError(err.message);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return {
    data,
    loading,
    error,
    refreshData: () => loadData(true)
  };
};

/**
 * Data Analysis and Statistics
 */
export const calculateStats = (data) => {
  try {
    if (!Array.isArray(data) || !data.length) {
      return { average: 0, min: 0, max: 0, count: 0 };
    }
    
    const readings = data
      .map(d => parseFloat(d.value))
      .filter(value => !isNaN(value));
      
    if (!readings.length) {
      return { average: 0, min: 0, max: 0, count: 0 };
    }

    return {
      average: readings.reduce((a, b) => a + b, 0) / readings.length,
      min: Math.min(...readings),
      max: Math.max(...readings),
      count: readings.length
    };
  } catch (error) {
    console.error('Error calculating stats:', error);
    return { average: 0, min: 0, max: 0, count: 0 };
  }
};

/**
 * Calendar Helper Functions
 */
export const calendarUtils = {
  getDotColor: (value, [min, max]) => {
    const numValue = Number(value);
    return !isNaN(numValue) && numValue >= min && numValue <= max 
      ? 'var(--clrGood)' 
      : 'var(--clrBad)';
  },

  processCalendarData: (data) => {
    return data.reduce((acc, reading) => {
      const date = new Date(reading.values[0]).toLocaleDateString('en-US');
      if (!acc[date]) acc[date] = [];
      acc[date].push(reading.values);
      return acc;
    }, {});
  }
};
/* - - - - - - - - - - - - - - - - */